// Angular
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
// RxJs
import { take } from 'rxjs/operators';
// Atomics libs
import { TranslocoAdapterService } from '@atomics/adapters';
// Portfolio MLV Core
import { titlesKeys } from '@portfolio-mlv/core/internationalization';
// Portfolio MLV Shared
import { appConstants } from '@portfolio-mlv/shared';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private translocoAdapterService: TranslocoAdapterService
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.translocoAdapterService
        .selectTranslate(`${titlesKeys.TITLES}.${title}`)
        .pipe(take(1))
        .subscribe((translatedTitle) => {
          this.title.setTitle(
            `${appConstants.META_TITLE} | ${translatedTitle}`
          );
        });
    }
  }
}
