import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

export const ROUTER_FEATURE_KEY = 'router';

/**
 * Each reducer is like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  readonly [ROUTER_FEATURE_KEY]: fromRouter.RouterReducerState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  [ROUTER_FEATURE_KEY]: fromRouter.routerReducer,
};
