// Angular
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import localeIt from '@angular/common/locales/it';
// Atomics libs
import { AdaptersModule, LoggerAdapterService } from '@atomics/adapters';
import {
  ThemesModule as AtomicsThemesModule,
  themeConstants,
} from '@atomics/themes';
import { UIModule as AtomicsUIModule } from '@atomics/ui';
// Portfolio MLV Shared
import { SharedModule as AppSharedModule } from '@portfolio-mlv/shared';
// Portfolio MLV Auth
// import { AuthModule } from '@portfolio-mlv/auth';
// Portfolio MLV Core (current module)
import { ErrorInterceptor } from './interceptors';
import {
  TranslocoRootModule,
  services as fromI18nServices,
} from './internationalization';
import { LoggerRootModule } from './logger';
import { MetaTagsModule } from './meta-tags';
import { StoreRootModule } from './store';
import { uiTokens } from './ui';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once.guard';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AppSharedModule.forRoot(),
    TranslocoRootModule,
    LoggerRootModule,
    MetaTagsModule,
    StoreRootModule,
    AtomicsThemesModule.forRoot({
      themes: [themeConstants.LIGHT, themeConstants.DARK],
      active: themeConstants.LIGHT,
      uiTokens,
    }),
    AtomicsUIModule,
    AdaptersModule.forRoot({ router: { shouldReuseRoute: false } }),
  ],
  exports: [
    HttpClientModule,
    TranslocoRootModule,
    LoggerRootModule,
    AtomicsThemesModule,
    AtomicsUIModule,
  ],
  declarations: [],
  providers: [
    ...fromI18nServices,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private logger: LoggerAdapterService
  ) {
    super(parentModule);
    // this.logger.debug(MODULE_NAME, 'constructor', `Locale is: [${appConstants.LOCALE}]`);
  }
}
