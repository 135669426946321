// Angular
import { inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
// Portfolio MLV Core
import { CoreModule } from './core';
// Portfolio MLV Landing
import { landingRoutesNames } from '@portfolio-mlv/landing';
// App (current module)
import { AppComponent } from './app.component';

export const APP_ROUTES: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: `${landingRoutesNames.LANDING}` },
  {
    path: ``,
    loadChildren: () => import('./landing').then((m) => m.LandingModule),
  },
  {
    path: '**',
    redirectTo: `${landingRoutesNames.LANDING}`,
  },
];
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(APP_ROUTES, { onSameUrlNavigation: 'reload' }),
    // Singleton objects (services, components that are loaded only once, etc.)
    // Auth module is imported into the core module
    CoreModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }
