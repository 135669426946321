// Angular
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
// Env
import { environment } from '@portfolio-mlv/environments';
// EMV Customer Portal Core Meta (current module)
import { metaTagsNames } from '../constants';

@Injectable()
export class MetaTagsService {
  constructor(private metaService: Meta) {}

  updateVersionMetaTag() {
    this.metaService.updateTag({
      name: metaTagsNames.VERSION,
      content: this.getVersionInfo(),
    });
  }

  private getVersionInfo(): string {
    // version info from GIT?
    // We should return a string, but here we have an object
    //return { version: 'v0.0.0' };
    return environment.version;
  }
}
