// Angular
import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
// RxJs
import { tap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
// NgRx
import { createEffect } from '@ngrx/effects';
// Transloco
import { TranslocoEvents } from '@ngneat/transloco';
// Portfolio MLV Core Internationalization
import { titlesKeys } from '@portfolio-mlv/core/internationalization';
// Portfolio MLV Shared
import { appConstants } from '@portfolio-mlv/shared';

@Injectable()
export class RouterEffects {
  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) {}
}
