// Angular
import { NgModule } from '@angular/core';
// Ngx Logger
import { LoggerModule } from 'ngx-logger';
// Env
import { environment } from '@portfolio-mlv/environments';

@NgModule({
  imports: [
    LoggerModule.forRoot({
      serverLoggingUrl: environment.logger.serverUrl,
      level: environment.logger.level,
      serverLogLevel: environment.logger.serverLevel,
    }),
  ],
})
export class LoggerRootModule {}
