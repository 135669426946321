import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, EventEmitter, Directive, NgModule } from '@angular/core';
import { DOCUMENT, CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const THEMES = new InjectionToken('THEMES');
const ACTIVE_THEME = new InjectionToken('ACTIVE_THEME');
const UI_TOKENS = new InjectionToken('UI_TOKENS');
const themeConstants = {
  DARK: 'dark',
  LIGHT: 'light'
};

// Angular
class TokenConnectionService {
  constructor(uiTokens, themes) {
    this.uiTokens = uiTokens;
    this.themes = themes;
  }
  getThemes() {
    return [this.getLightTokens(), this.getDarkTokens()];
  }
  getDarkTokens() {
    ``;
    return {
      name: 'dark',
      properties: {
        // DARK THEME VALUES
        // COLORS
        // BACKGROUNDS
        '--bgDepth': this.uiTokens.dark?.colors?.backgrounds?.depth,
        '--bgFrame': this.uiTokens.dark?.colors?.backgrounds?.frame,
        '--bgSurface': this.uiTokens.dark?.colors?.backgrounds?.surface,
        '--bgSurfaceAlt': this.uiTokens.dark?.colors?.backgrounds?.surfaceAlt,
        '--bgSurfaceOpacity': this.uiTokens.dark?.colors?.backgrounds?.surfaceOpacity,
        '--bgSurfacePrimary': this.uiTokens.dark?.colors?.backgrounds?.surfacePrimary,
        '--bgPrimary': this.uiTokens.dark?.colors?.backgrounds?.primary,
        '--bgHover': this.uiTokens.dark?.colors?.backgrounds?.status?.hover,
        '--bgHoverAlt': this.uiTokens.dark?.colors?.backgrounds?.status?.hoverAlt,
        '--bgActive': this.uiTokens.dark?.colors?.backgrounds?.status?.active,
        '--bgDisabled': this.uiTokens.dark?.colors?.backgrounds?.status?.disabled,
        '--bgErrorFull': this.uiTokens.dark?.colors?.backgrounds?.status?.errorFull,
        '--bgErrorOpacity': this.uiTokens.dark?.colors?.backgrounds?.status?.errorOpacity,
        '--bgSuccessFull': this.uiTokens.dark?.colors?.backgrounds?.status?.successFull,
        '--bgSuccessOpacity': this.uiTokens.dark?.colors?.backgrounds?.status?.successOpacity,
        '--bgWarningFull': this.uiTokens.dark?.colors?.backgrounds?.status?.warningFull,
        '--bgWarningOpacity': this.uiTokens.dark?.colors?.backgrounds?.status?.warningOpacity,
        // BRAND:
        '--brndPrimary0500': this.uiTokens.dark?.colors?.brand?.primary?.primary0500,
        '--brndPrimary116': this.uiTokens.dark?.colors?.brand?.primary?.primary116,
        '--brndPrimary124': this.uiTokens.dark?.colors?.brand?.primary?.primary124,
        '--brndPrimary140': this.uiTokens.dark?.colors?.brand?.primary?.primary140,
        '--brndPrimary200': this.uiTokens.dark?.colors?.brand?.primary?.primary200,
        '--brndPrimary300': this.uiTokens.dark?.colors?.brand?.primary?.primary300,
        '--brndPrimary400': this.uiTokens.dark?.colors?.brand?.primary?.primary400,
        '--brndPrimary600': this.uiTokens.dark?.colors?.brand?.primary?.primary600,
        '--brndPrimary700': this.uiTokens.dark?.colors?.brand?.primary?.primary700,
        '--brndPrimary800': this.uiTokens.dark?.colors?.brand?.primary?.primary800,
        // OVERLAYS
        '--ovrPrimary': this.uiTokens.dark?.colors?.overlays?.primary,
        // TEXTS
        '--txBrndPrimary': this.uiTokens.dark?.colors?.texts?.brandPrimary,
        '--txPrimary': this.uiTokens.dark?.colors?.texts?.primary,
        '--txSecondary': this.uiTokens.dark?.colors?.texts?.secondary,
        '--txNeutral': this.uiTokens.dark?.colors?.texts?.neutral,
        '--txInverted': this.uiTokens.dark?.colors?.texts?.inverted,
        '--txDisabled': this.uiTokens.dark?.colors?.texts?.status?.disabled,
        '--txError': this.uiTokens.dark?.colors?.texts?.status?.error,
        '--txSuccess': this.uiTokens.dark?.colors?.texts?.status?.success,
        '--txWarning': this.uiTokens.dark?.colors?.texts?.status?.warning,
        // SHADOWS
        '--sdwNeutral': this.uiTokens.dark?.shadows?.neutral,
        // COMMON VALUES
        // BASE UNIT
        '--buXS': this.uiTokens.common?.baseUnit?.xs,
        '--buSM': this.uiTokens.common?.baseUnit?.sm,
        '--buMD': this.uiTokens.common?.baseUnit?.md,
        '--buLG': this.uiTokens.common?.baseUnit?.lg,
        '--buXL': this.uiTokens.common?.baseUnit?.xl,
        // FONTFAMILY
        '--tfBody': this.uiTokens.common?.txFamily?.body,
        '--tfHeaders': this.uiTokens.common?.txFamily?.headers,
        // FONTSIZE
        '--fsh1': this.uiTokens.common?.txSize?.heading?.h1,
        '--fsh2': this.uiTokens.common?.txSize?.heading?.h2,
        '--fsh3': this.uiTokens.common?.txSize?.heading?.h3,
        '--fsh4': this.uiTokens.common?.txSize?.heading?.h4,
        '--fsh5': this.uiTokens.common?.txSize?.heading?.h5,
        '--fsh6': this.uiTokens.common?.txSize?.heading?.h6,
        '--fspXS': this.uiTokens.common?.txSize?.content?.pXS,
        '--fspSM': this.uiTokens.common?.txSize?.content?.pSM,
        '--fspMD': this.uiTokens.common?.txSize?.content?.pMD,
        '--fspLG': this.uiTokens.common?.txSize?.content?.pLG,
        '--fspXL': this.uiTokens.common?.txSize?.content?.pXL
      }
    };
  }
  getLightTokens() {
    return {
      name: 'light',
      properties: {
        // LIGHT THEME VALUES
        // COLORS
        // BACKGROUNDS
        '--bgDepth': this.uiTokens.light?.colors?.backgrounds?.depth,
        '--bgFrame': this.uiTokens.light?.colors?.backgrounds?.frame,
        '--bgSurface': this.uiTokens.light?.colors?.backgrounds?.surface,
        '--bgSurfaceAlt': this.uiTokens.light?.colors?.backgrounds?.surfaceAlt,
        '--bgSurfaceOpacity': this.uiTokens.light?.colors?.backgrounds?.surfaceOpacity,
        '--bgSurfacePrimary': this.uiTokens.light?.colors?.backgrounds?.surfacePrimary,
        '--bgPrimary': this.uiTokens.light?.colors?.backgrounds?.primary,
        '--bgHover': this.uiTokens.light?.colors?.backgrounds?.status?.hover,
        '--bgHoverAlt': this.uiTokens.light?.colors?.backgrounds?.status?.hoverAlt,
        '--bgActive': this.uiTokens.light?.colors?.backgrounds?.status?.active,
        '--bgDisabled': this.uiTokens.light?.colors?.backgrounds?.status?.disabled,
        '--bgErrorFull': this.uiTokens.light?.colors?.backgrounds?.status?.errorFull,
        '--bgErrorOpacity': this.uiTokens.light?.colors?.backgrounds?.status?.errorOpacity,
        '--bgSuccessFull': this.uiTokens.light?.colors?.backgrounds?.status?.successFull,
        '--bgSuccessOpacity': this.uiTokens.light?.colors?.backgrounds?.status?.successOpacity,
        '--bgWarningFull': this.uiTokens.light?.colors?.backgrounds?.status?.warningFull,
        '--bgWarningOpacity': this.uiTokens.light?.colors?.backgrounds?.status?.warningOpacity,
        // BRAND:
        '--brndPrimary0500': this.uiTokens.light?.colors?.brand?.primary?.primary0500,
        '--brndPrimary116': this.uiTokens.light?.colors?.brand?.primary?.primary116,
        '--brndPrimary124': this.uiTokens.light?.colors?.brand?.primary?.primary124,
        '--brndPrimary140': this.uiTokens.light?.colors?.brand?.primary?.primary140,
        '--brndPrimary200': this.uiTokens.light?.colors?.brand?.primary?.primary200,
        '--brndPrimary300': this.uiTokens.light?.colors?.brand?.primary?.primary300,
        '--brndPrimary400': this.uiTokens.light?.colors?.brand?.primary?.primary400,
        '--brndPrimary600': this.uiTokens.light?.colors?.brand?.primary?.primary600,
        '--brndPrimary700': this.uiTokens.light?.colors?.brand?.primary?.primary700,
        '--brndPrimary800': this.uiTokens.light?.colors?.brand?.primary?.primary800,
        // OVERLAYS
        '--ovrPrimary': this.uiTokens.light?.colors?.overlays?.primary,
        // TEXTS
        '--txBrndPrimary': this.uiTokens.light?.colors?.texts?.brandPrimary,
        '--txPrimary': this.uiTokens.light?.colors?.texts?.primary,
        '--txSecondary': this.uiTokens.light?.colors?.texts?.secondary,
        '--txNeutral': this.uiTokens.dark?.colors?.texts?.neutral,
        '--txInverted': this.uiTokens.light?.colors?.texts?.inverted,
        '--txDisabled': this.uiTokens.light?.colors?.texts?.status?.disabled,
        '--txError': this.uiTokens.light?.colors?.texts?.status?.error,
        '--txSuccess': this.uiTokens.light?.colors?.texts?.status?.success,
        '--txWarning': this.uiTokens.light?.colors?.texts?.status?.warning,
        // SHADOWS
        '--sdwNeutral': this.uiTokens.light?.shadows?.neutral,
        // COMMON VALUES
        // BASE UNITtxS
        '--buXS': this.uiTokens.common?.baseUnit?.xs,
        '--buSM': this.uiTokens.common?.baseUnit?.sm,
        '--buMD': this.uiTokens.common?.baseUnit?.md,
        '--buLG': this.uiTokens.common?.baseUnit?.lg,
        '--buXL': this.uiTokens.common?.baseUnit?.xl,
        // FONTFAMILY
        '--tfBody': this.uiTokens.common?.txFamily?.body,
        '--tfHeaders': this.uiTokens.common?.txFamily?.headers,
        // FONTSIZE
        '--fsh1': this.uiTokens.common?.txSize?.heading?.h1,
        '--fsh2': this.uiTokens.common?.txSize?.heading?.h2,
        '--fsh3': this.uiTokens.common?.txSize?.heading?.h3,
        '--fsh4': this.uiTokens.common?.txSize?.heading?.h4,
        '--fsh5': this.uiTokens.common?.txSize?.heading?.h5,
        '--fsh6': this.uiTokens.common?.txSize?.heading?.h6,
        '--fspXS': this.uiTokens.common?.txSize?.content?.pXS,
        '--fspSM': this.uiTokens.common?.txSize?.content?.pSM,
        '--fspMD': this.uiTokens.common?.txSize?.content?.pMD,
        '--fspLG': this.uiTokens.common?.txSize?.content?.pLG,
        '--fspXL': this.uiTokens.common?.txSize?.content?.pXL
      }
    };
  }
  static {
    this.ɵfac = function TokenConnectionService_Factory(t) {
      return new (t || TokenConnectionService)(i0.ɵɵinject(UI_TOKENS), i0.ɵɵinject(THEMES));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TokenConnectionService,
      factory: TokenConnectionService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TokenConnectionService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [UI_TOKENS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [THEMES]
    }]
  }], null);
})();

// Angular
class ThemeService {
  constructor(themes, activeTheme, tokenConnectionService) {
    this.themes = themes;
    this.activeTheme = activeTheme;
    this.tokenConnectionService = tokenConnectionService;
    this.themeChange = new EventEmitter();
  }
  getActiveTheme() {
    let theme = null;
    if (!this.themes.includes(this.activeTheme)) {
      throw new Error(`Theme '${this.activeTheme}' not available in themes list.`);
    }
    if (this.activeTheme === themeConstants.LIGHT) {
      theme = this.tokenConnectionService.getLightTokens();
    } else if (this.activeTheme === themeConstants.DARK) {
      theme = this.tokenConnectionService.getDarkTokens();
    }
    // const theme = this.themes.find(t => t.name === this.activeTheme);
    if (!theme) {
      throw new Error(`Theme not found: '${this.activeTheme}'`);
    }
    // console.log(`Actual theme is ${theme.name}`);
    return theme;
  }
  setTheme(name) {
    this.activeTheme = name;
    this.themeChange.emit(this.getActiveTheme());
  }
  static {
    this.ɵfac = function ThemeService_Factory(t) {
      return new (t || ThemeService)(i0.ɵɵinject(THEMES), i0.ɵɵinject(ACTIVE_THEME), i0.ɵɵinject(TokenConnectionService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ThemeService,
      factory: ThemeService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [THEMES]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ACTIVE_THEME]
    }]
  }, {
    type: TokenConnectionService
  }], null);
})();
class ThemeDirective {
  constructor(document, _elementRef, _themeService, renderer) {
    this.document = document;
    this._elementRef = _elementRef;
    this._themeService = _themeService;
    this.renderer = renderer;
    this.unsubscribe = new Subject();
  }
  ngOnInit() {
    const active = this._themeService.getActiveTheme();
    if (active) {
      this.updateTheme(active);
    }
    this._themeService.themeChange.pipe(takeUntil(this.unsubscribe)).subscribe(theme => this.updateTheme(theme));
  }
  updateTheme(theme) {
    // const body = this.document.querySelector('body');
    // console.log(body);
    for (const key in theme.properties) {
      // this._elementRef.nativeElement.style.setProperty(key, theme.properties[key]);
      this.document.body.style.setProperty(key, theme.properties[key]);
    }
  }
  static {
    this.ɵfac = function ThemeDirective_Factory(t) {
      return new (t || ThemeDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(ThemeService), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ThemeDirective,
      selectors: [["", "app-theme", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeDirective, [{
    type: Directive,
    args: [{
      selector: '[app-theme]'
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: ThemeService
  }, {
    type: i0.Renderer2
  }], null);
})();

// Angular
class ThemesModule {
  static forRoot(options) {
    return {
      ngModule: ThemesModule,
      providers: [{
        provide: THEMES,
        useValue: options.themes
      }, {
        provide: ACTIVE_THEME,
        useValue: options.active
      }, {
        provide: UI_TOKENS,
        useValue: options.uiTokens
      }]
    };
  }
  static {
    this.ɵfac = function ThemesModule_Factory(t) {
      return new (t || ThemesModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ThemesModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [ThemeService, TokenConnectionService],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemesModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      providers: [ThemeService, TokenConnectionService],
      declarations: [ThemeDirective],
      exports: [ThemeDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ACTIVE_THEME, THEMES, ThemeDirective, ThemeService, ThemesModule, TokenConnectionService, UI_TOKENS, themeConstants };
