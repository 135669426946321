// Angular
import { NgModule } from '@angular/core';
// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
// Atomics libs
import { LoggerAdapterService } from '@atomics/adapters';
import { ToastModule } from '@atomics/ui';
// Env
import { environment } from '@portfolio-mlv/environments';
// Portfolio MLV Core Store
import { storeRootConstants } from './constants';
import { ErrorEffects, RouterEffects } from './effects';
import { metaReducers } from './meta-reducers';
import { reducers } from './reducers';

const MODULE_NAME = 'StateRootModule';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    // @ngrx/router-store keeps router state up-to-date in the store
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: storeRootConstants.STATE_NAME,
      // Disable all features in production
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([RouterEffects, ErrorEffects]),
    ToastModule.forRoot({
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    }),
  ],
})
export class StoreRootModule {
  constructor(private logger: LoggerAdapterService) {
    this.logger.debug(MODULE_NAME, 'constructor', `${MODULE_NAME} constructor`);
  }
}
