import { UiTokens } from '@atomics/ui-tokens';

export const uiTokens: UiTokens = {
  // COMMON tokens shared by both DARK & LIGHT Themes
  common: {
    baseUnit: {
      xs: '4px',
      sm: '8px',
      md: '16px',
      lg: '24px',
      xl: '32px',

    },
    txFamily: {
      body: 'Essential Sans',
      headers: 'Euclid Circular B',
    },
    txSize: {
      heading: {
        h1: '104px',
        h2: '80px',
        h3: '48px',
        h4: '32px',
        h5: '24px',
        h6: '20px',
      },
      content: {
        pXS: '12px',
        pSM: '14px',
        pMD: '16px',
        pLG: '18px',
        pXL: '20px',
      }
    }
  },
  // DARK & LIGHT Themes Values
  dark: {
    colors: {
      backgrounds: {
        depth: '#12030B',
        frame: '#18010D',
        surface: '#230615',
        surfaceAlt: '#371D2A',
        surfaceOpacity: '49323D',
        surfacePrimary: 'rgba(251, 247, 249, 0.40)',
        primary: '#3BB99F',
        status: {
          hover: '#F27D9C',
          hoverAlt: '#F6A2B8',
          active: '#DD2D5C',
          disabled: '#E6E9F0',
          errorFull: '#CA1616',
          errorOpacity: 'rgba(202, 22, 22, 0.24)',
          successFull: '#66D437',
          successOpacity: 'rgba(102, 212, 55, 0.24)',
          warningFull: '#FF9000',
          warningOpacity: 'rgba(255, 144, 0, 0.24)'
        }
      },
      brand: {
        primary: {
          primary0500: '#ED4672',
          primary116: 'rgba(251, 247, 249, 0.16)',
          primary124: 'rgba(251, 247, 249, 0.24))',
          primary140: 'rgba(251, 247, 249, 0.40)',
          primary200: '#F6A2B8',
          primary300: '#F27D9C',
          primary400: '#F06287',
          primary600: '#E53A67',
          primary700: '#DD2D5C',
          primary800: '#CC1445',
        }
      },
      overlays: {
        primary: 'rgba(22, 25, 34, 0.64)',
      },
      texts: {
        brandPrimary: '#ED4672',
        primary: '#FFFFFF',
        secondary: '#9AA0AC',
        neutral: '#C4C8D1',
        inverted: '#171717',
        status: {
          disabled: '#A5ACB6',
          error: '#CA1616',
          success: '#257A00',
          warning: '#E26200',
        }
      },
    },
    shadows: {
      neutral: '0 2px 8px 0 rgba(0, 0, 0, 1)',
    }
  },
  light: {
    colors: {
      backgrounds: {
        depth: '#F2F5F7',
        frame: '#FAFBFC',
        surface: '#FFFFFF',
        surfaceAlt: '#F2F5F7',
        surfaceOpacity: 'rgba(255, 255, 255, 0.32)',
        surfacePrimary: 'rgba(251, 247, 249, 0.16)',
        primary: '#ED4672',
        status: {
          hover: '#F27D9C',
          hoverAlt: '#F6A2B8',
          active: '#DD2D5C',
          disabled: '#E6E9F0',
          errorFull: '#CA1616',
          errorOpacity: 'rgba(202, 22, 22, 0.24)',
          successFull: '#66D437',
          successOpacity: 'rgba(102, 212, 55, 0.24)',
          warningFull: '#FF9000',
          warningOpacity: 'rgba(255, 144, 0, 0.24)'
        }
      },
      brand: {
        primary: {
          primary0500: '#ED4672',
          primary116: 'rgba(251, 247, 249, 0.16)',
          primary124: 'rgba(251, 247, 249, 0.24))',
          primary140: 'rgba(251, 247, 249, 0.40)',
          primary200: '#F6A2B8',
          primary300: '#F27D9C',
          primary400: '#F06287',
          primary600: '#E53A67',
          primary700: '#DD2D5C',
          primary800: '#CC1445',
        }
      },
      overlays: {
        primary: 'rgba(237, 70, 114, 0.64)',
      },
      texts: {
        brandPrimary: '#ED4672',
        primary: '#171717',
        secondary: '#404040',
        neutral: '#707070',
        inverted: '#FFFFFF',
        status: {
          disabled: '#A5ACB6',
          error: '#CA1616',
          success: '#257A00',
          warning: '#E26200',
        }
      },
    },
    shadows: {
      neutral: '0 2px 8px 0 rgba(154, 160, 172, 0.24)',
    }
  },
};
