// Angular
import { Injectable } from '@angular/core';
// RxJs
import { tap, withLatestFrom } from 'rxjs/operators';
// NgRx
import { Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
// Atomics libs
import { RouterAdapterService } from '@atomics/adapters';
import { ToastService } from '@atomics/ui';
// Portfolio MLV Landing
import { landingRoutes } from '@portfolio-mlv/landing';
// Portfolio MLV Core (current module)
import { ErrorInterceptorActions } from '../actions';

@Injectable()
export class ErrorEffects {
  errorInterceptedToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ErrorInterceptorActions.errorInterceptedToast),
        tap(({ data }) => this.toastService.openToastBasic(data)),
        tap((action) => {
          this.routerAdapterService.navigateByUrl(landingRoutes.LANDING);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private routerAdapterService: RouterAdapterService,
    private store: Store,
    private toastService: ToastService
  ) {}
}
