// Angular
import { NgModule } from '@angular/core';
import { TitleStrategy } from '@angular/router';
// EMV Customer Portal Core Meta Tags (current module)
import { MetaTagsService, TemplatePageTitleStrategy } from './services';

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    MetaTagsService,
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
  ],
})
export class MetaTagsModule {
  constructor(private metaTagsService: MetaTagsService) {
    this.metaTagsService.updateVersionMetaTag();
  }
}
