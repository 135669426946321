// Angular
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
// RxJs
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// NgRx
import { Store } from '@ngrx/store';
// Atomics libs
import { svgIconsConsts } from '@atomics/icons';
import { TranslocoAdapterService } from '@atomics/adapters';
import { ToastData } from '@atomics/ui';
// Portfolio MLV Auth
// import { AuthActions } from '@portfolio-mlv/auth';
// Portfolio MLV Shared
import { ToastAdapterService } from '@portfolio-mlv/shared/adapters';
// Portfolio MLV Landing
import { landingRoutes } from '@portfolio-mlv/landing';
// Portfolio MLV Core (current module)
import { ErrorInterceptorActions } from '../store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private translocoAdapterService: TranslocoAdapterService,
    private store: Store,
    private toastAdapterService: ToastAdapterService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        let handled: boolean = false;

        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
          } else {
            const { status } = err;
            switch (status) {
              case 401:
              case 403:
                // Auto logout if
                // 401 Unauthorized or
                // 403 Forbidden response returned from api
                // this.store.dispatch(AuthActions.navigateByUrl({ url: landingRoutes.LANDING }));
                handled = false;
                break;
              default:
                const toastData: ToastData =
                  this.toastAdapterService.toToastDataError();
                this.store.dispatch(
                  ErrorInterceptorActions.errorInterceptedToast({
                    data: toastData,
                  })
                );
                handled = true;
                break;
            }
          }
        } else {
          // Not HTTP error response
        }

        if (handled) {
          return of(err);
        } else {
          // Propagate the error
          return throwError(() => err);
        }
      })
    );
  }
}
